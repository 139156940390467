/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { RiArrowRightSLine, RiCheckLine, RiStarLine, RiTimeLine, RiQuestionLine, RiDownloadLine, RiFileTextLine } from "react-icons/ri"

const GoalSettingTemplatePage = () => {
  const goalCategories = [
    {
      title: "Professional Development",
      examples: [
        "Complete relevant certification in field",
        "Improve presentation skills",
        "Expand professional network"
      ]
    },
    {
      title: "Performance Goals",
      examples: [
        "Increase sales by 15% in Q3",
        "Reduce project delivery time by 20%",
        "Improve customer satisfaction scores"
      ]
    },
    {
      title: "Leadership Skills",
      examples: [
        "Mentor two junior team members",
        "Lead three major projects",
        "Improve team collaboration"
      ]
    }
  ]

  return (
    <Layout>
      <SEO 
        title="SMART Goal Setting Template | Professional Development Planning Tool"
        description="Create effective SMART goals with our comprehensive template. Perfect for performance reviews, professional development, and team alignment. Free downloadable templates included."
        keywords={[
          "SMART goals template",
          "goal setting framework",
          "professional development goals",
          "performance objectives",
          "employee goal setting",
          "career development planning",
          "SMART criteria template",
          "goal tracking template",
          "performance management tools",
          "objective setting guide"
        ]}
      />
      
      {/* Hero Section */}
      <div sx={{
        background: 'linear-gradient(135deg, #f8f9fa 0%, #e9ecef 100%)',
        py: [4, 5],
        position: 'relative'
      }}>
        <div sx={{
          maxWidth: '1200px',
          mx: 'auto',
          px: 3
        }}>
          <div sx={{
            textAlign: 'center',
            maxWidth: '800px',
            mx: 'auto'
          }}>
            <h1 sx={{
              fontSize: ['2rem', '2.5rem'],
              fontWeight: 700,
              mb: 3,
              color: 'primary'
            }}>
              SMART Goal Setting Template
            </h1>
            
            <p sx={{
              fontSize: '1.1rem',
              lineHeight: 1.5,
              mb: 4,
              color: 'text',
              opacity: 0.9
            }}>
              Create clear, actionable, and measurable goals that drive success. 
              Use our template to develop professional goals aligned with both individual 
              and organizational objectives.
            </p>
            
            <div sx={{
              display: 'flex',
              gap: 3,
              justifyContent: 'center',
              flexWrap: 'wrap'
            }}>
              <a href="#download" sx={{
                display: 'inline-flex',
                alignItems: 'center',
                gap: 2,
                bg: 'primary',
                color: 'white',
                py: 2,
                px: 3,
                borderRadius: '6px',
                fontSize: '0.95rem',
                fontWeight: 600,
                textDecoration: 'none',
                transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                boxShadow: '0 4px 10px rgba(0,0,0,0.1)',
                '&:hover': {
                  transform: 'translateY(-2px)',
                  boxShadow: '0 6px 15px rgba(0,0,0,0.15)'
                }
              }}>
                Download Template <RiDownloadLine />
              </a>
              
              <a href="#examples" sx={{
                display: 'inline-flex',
                alignItems: 'center',
                gap: 2,
                bg: 'white',
                color: 'primary',
                py: 2,
                px: 3,
                borderRadius: '6px',
                fontSize: '0.95rem',
                fontWeight: 600,
                textDecoration: 'none',
                border: '2px solid',
                borderColor: 'primary',
                transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                boxShadow: '0 4px 10px rgba(0,0,0,0.05)',
                '&:hover': {
                  transform: 'translateY(-2px)',
                  boxShadow: '0 6px 15px rgba(0,0,0,0.1)'
                }
              }}>
                View Examples <RiFileTextLine />
              </a>
            </div>
          </div>
        </div>
      </div>
      
      {/* Main Content */}
      <div sx={{
        bg: 'white',
        py: [4, 5]
      }}>
        <div sx={{
          maxWidth: '1200px',
          mx: 'auto',
          px: 3,
          display: 'grid',
          gridTemplateColumns: ['1fr', '1fr', '2fr 1fr'],
          gap: 5
        }}>
          {/* Left Column - Main Content */}
          <div>
            {/* SMART Framework */}
            <div sx={{ 
              mb: 4,
              pb: 4,
              borderBottom: '1px solid',
              borderColor: 'rgba(0,0,0,0.06)'
            }}>
              <h2 sx={{
                fontSize: '1.75rem',
                fontWeight: 700,
                color: 'text',
                mb: 3
              }}>
                The SMART Goal Framework
              </h2>
              
              <p sx={{
                fontSize: '1rem',
                color: 'text',
                lineHeight: 1.6,
                mb: 4
              }}>
                SMART goals provide a clear framework for setting objectives that are achievable 
                and measurable. Each component ensures your goals are well-defined and actionable.
              </p>
              
              <div sx={{
                display: 'grid',
                gridTemplateColumns: ['1fr', '1fr 1fr'],
                gap: 3,
                mb: 4
              }}>
                <div sx={{
                  bg: 'backgroundMuted',
                  p: 3,
                  borderRadius: '8px'
                }}>
                  <h3 sx={{
                    fontSize: '1.25rem',
                    fontWeight: 600,
                    color: 'text',
                    mb: 2,
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2
                  }}>
                    <RiStarLine sx={{ color: 'primary' }} />
                    Specific
                  </h3>
                  <p sx={{
                    fontSize: '0.95rem',
                    color: 'text',
                    lineHeight: 1.5
                  }}>
                    Clearly define what you want to accomplish. Answer the five W's: What, Why, Who, Where, and Which.
                  </p>
                </div>
                
                <div sx={{
                  bg: 'backgroundMuted',
                  p: 3,
                  borderRadius: '8px'
                }}>
                  <h3 sx={{
                    fontSize: '1.25rem',
                    fontWeight: 600,
                    color: 'text',
                    mb: 2,
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2
                  }}>
                    <RiStarLine sx={{ color: 'primary' }} />
                    Measurable
                  </h3>
                  <p sx={{
                    fontSize: '0.95rem',
                    color: 'text',
                    lineHeight: 1.5
                  }}>
                    Include specific metrics to track progress and determine success. Quantify your objectives.
                  </p>
                </div>
                
                <div sx={{
                  bg: 'backgroundMuted',
                  p: 3,
                  borderRadius: '8px'
                }}>
                  <h3 sx={{
                    fontSize: '1.25rem',
                    fontWeight: 600,
                    color: 'text',
                    mb: 2,
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2
                  }}>
                    <RiStarLine sx={{ color: 'primary' }} />
                    Achievable
                  </h3>
                  <p sx={{
                    fontSize: '0.95rem',
                    color: 'text',
                    lineHeight: 1.5
                  }}>
                    Set goals that are challenging but realistic given your resources and constraints.
                  </p>
                </div>
                
                <div sx={{
                  bg: 'backgroundMuted',
                  p: 3,
                  borderRadius: '8px'
                }}>
                  <h3 sx={{
                    fontSize: '1.25rem',
                    fontWeight: 600,
                    color: 'text',
                    mb: 2,
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2
                  }}>
                    <RiStarLine sx={{ color: 'primary' }} />
                    Relevant
                  </h3>
                  <p sx={{
                    fontSize: '0.95rem',
                    color: 'text',
                    lineHeight: 1.5
                  }}>
                    Align goals with broader career and organizational objectives. Ensure they matter.
                  </p>
                </div>
                
                <div sx={{
                  bg: 'backgroundMuted',
                  p: 3,
                  borderRadius: '8px',
                  gridColumn: ['auto', '1 / -1']
                }}>
                  <h3 sx={{
                    fontSize: '1.25rem',
                    fontWeight: 600,
                    color: 'text',
                    mb: 2,
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2
                  }}>
                    <RiStarLine sx={{ color: 'primary' }} />
                    Time-bound
                  </h3>
                  <p sx={{
                    fontSize: '0.95rem',
                    color: 'text',
                    lineHeight: 1.5
                  }}>
                    Set specific deadlines and milestones. Create urgency and track progress over time.
                  </p>
                </div>
              </div>
            </div>
            
            {/* Goal Examples */}
            <div id="examples" sx={{ 
              mb: 4,
              pb: 4,
              borderBottom: '1px solid',
              borderColor: 'rgba(0,0,0,0.06)'
            }}>
              <h2 sx={{
                fontSize: '1.75rem',
                fontWeight: 700,
                color: 'text',
                mb: 3
              }}>
                Goal Examples by Category
              </h2>
              
              <p sx={{
                fontSize: '1rem',
                color: 'text',
                lineHeight: 1.6,
                mb: 4
              }}>
                Browse example goals across different categories to inspire your own goal setting:
              </p>
              
              {goalCategories.map((category, idx) => (
                <div key={`category-${category.title}`} sx={{
                  mb: idx === goalCategories.length - 1 ? 0 : 4
                }}>
                  <h3 sx={{
                    fontSize: '1.25rem',
                    fontWeight: 600,
                    color: 'text',
                    mb: 2
                  }}>
                    {category.title}
                  </h3>
                  
                  <ul sx={{
                    listStyle: 'none',
                    p: 0,
                    m: 0
                  }}>
                    {category.examples.map((example, exampleIndex) => (
                      <li key={`example-${category.title}-${exampleIndex}`} sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 2,
                        mb: 2
                      }}>
                        <RiCheckLine sx={{ color: 'primary', flexShrink: 0 }} />
                        <span sx={{
                          fontSize: '0.95rem',
                          color: 'text',
                          lineHeight: 1.5
                        }}>
                          {example}
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
            
            {/* Template Usage Guide */}
            <div sx={{ mb: 4 }}>
              <h2 sx={{
                fontSize: '1.75rem',
                fontWeight: 700,
                color: 'text',
                mb: 3
              }}>
                How to Use the Template
              </h2>
              
              <ol sx={{
                pl: '1.5rem',
                mb: 0
              }}>
                <li sx={{
                  fontSize: '1rem',
                  color: 'text',
                  lineHeight: 1.6,
                  mb: 2
                }}>
                  <strong>Define Your Goal Area:</strong> Start by selecting the category your goal falls under (professional development, performance, leadership, etc.).
                </li>
                <li sx={{
                  fontSize: '1rem',
                  color: 'text',
                  lineHeight: 1.6,
                  mb: 2
                }}>
                  <strong>Write Your Initial Goal:</strong> Draft your goal in clear, action-oriented language.
                </li>
                <li sx={{
                  fontSize: '1rem',
                  color: 'text',
                  lineHeight: 1.6,
                  mb: 2
                }}>
                  <strong>Apply SMART Criteria:</strong> Review and refine your goal using each element of the SMART framework.
                </li>
                <li sx={{
                  fontSize: '1rem',
                  color: 'text',
                  lineHeight: 1.6,
                  mb: 2
                }}>
                  <strong>Set Milestones:</strong> Break down your goal into smaller, manageable steps with deadlines.
                </li>
                <li sx={{
                  fontSize: '1rem',
                  color: 'text',
                  lineHeight: 1.6,
                  mb: 0
                }}>
                  <strong>Review and Adjust:</strong> Regularly review progress and adjust goals as needed.
                </li>
              </ol>
            </div>
          </div>
          
          {/* Right Column - Sidebar */}
          <div>
            {/* Download Template */}
            <div id="download" sx={{
              bg: 'white',
              p: 4,
              borderRadius: '8px',
              boxShadow: '0 2px 10px rgba(0,0,0,0.08)',
              mb: 4
            }}>
              <h3 sx={{
                fontSize: '1.25rem',
                fontWeight: 600,
                color: 'text',
                mb: 3,
                display: 'flex',
                alignItems: 'center',
                gap: 2
              }}>
                <RiDownloadLine sx={{ color: 'primary' }} />
                Download Templates
              </h3>
              
              <p sx={{
                fontSize: '0.95rem',
                color: 'text',
                lineHeight: 1.5,
                mb: 3
              }}>
                Get our professionally designed goal setting templates in multiple formats. Customize them for your organization.
              </p>
              
              <div sx={{
                display: 'grid',
                gap: 2,
                mb: 0
              }}>
                <Link to="/contact" sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2,
                  bg: 'backgroundMuted',
                  color: 'text',
                  p: 2,
                  borderRadius: '6px',
                  textDecoration: 'none',
                  transition: 'transform 0.2s ease, background-color 0.2s ease',
                  '&:hover': {
                    bg: 'rgba(0,0,0,0.04)',
                    transform: 'translateY(-2px)'
                  }
                }}>
                  <RiDownloadLine sx={{ color: 'primary' }} />
                  <div>
                    <strong>Word Templates</strong>
                    <span sx={{ display: 'block', fontSize: '0.8rem', opacity: 0.8 }}>Editable .docx format</span>
                  </div>
                </Link>
                
                <Link to="/contact" sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2,
                  bg: 'backgroundMuted',
                  color: 'text',
                  p: 2,
                  borderRadius: '6px',
                  textDecoration: 'none',
                  transition: 'transform 0.2s ease, background-color 0.2s ease',
                  '&:hover': {
                    bg: 'rgba(0,0,0,0.04)',
                    transform: 'translateY(-2px)'
                  }
                }}>
                  <RiDownloadLine sx={{ color: 'primary' }} />
                  <div>
                    <strong>PDF Templates</strong>
                    <span sx={{ display: 'block', fontSize: '0.8rem', opacity: 0.8 }}>Print-ready versions</span>
                  </div>
                </Link>
                
                <Link to="/contact" sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2,
                  bg: 'backgroundMuted',
                  color: 'text',
                  p: 2,
                  borderRadius: '6px',
                  textDecoration: 'none',
                  transition: 'transform 0.2s ease, background-color 0.2s ease',
                  '&:hover': {
                    bg: 'rgba(0,0,0,0.04)',
                    transform: 'translateY(-2px)'
                  }
                }}>
                  <RiDownloadLine sx={{ color: 'primary' }} />
                  <div>
                    <strong>Excel Tracker</strong>
                    <span sx={{ display: 'block', fontSize: '0.8rem', opacity: 0.8 }}>Goal tracking spreadsheet</span>
                  </div>
                </Link>
              </div>
            </div>
            
            {/* Quick Tips */}
            <div sx={{
              bg: 'backgroundMuted',
              p: 3,
              borderRadius: '8px',
              mb: 4,
              boxShadow: '0 2px 6px rgba(0,0,0,0.05)'
            }}>
              <h3 sx={{
                fontSize: '1.25rem',
                fontWeight: 600,
                color: 'primary',
                mb: 3
              }}>
                Quick Tips
              </h3>
              
              <div sx={{
                mb: 3
              }}>
                <div sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2,
                  mb: 1
                }}>
                  <RiCheckLine sx={{ color: 'primary' }} />
                  <p sx={{
                    fontSize: '0.95rem',
                    fontWeight: 600,
                    color: 'text'
                  }}>
                    Start with the End in Mind
                  </p>
                </div>
                
                <p sx={{
                  fontSize: '0.9rem',
                  color: 'text',
                  opacity: 0.8,
                  lineHeight: 1.5,
                  ml: 4
                }}>
                  Visualize your desired outcome before setting specific goals.
                </p>
              </div>
              
              <div sx={{
                mb: 3
              }}>
                <div sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2,
                  mb: 1
                }}>
                  <RiCheckLine sx={{ color: 'primary' }} />
                  <p sx={{
                    fontSize: '0.95rem',
                    fontWeight: 600,
                    color: 'text'
                  }}>
                    Track Progress
                  </p>
                </div>
                
                <p sx={{
                  fontSize: '0.9rem',
                  color: 'text',
                  opacity: 0.8,
                  lineHeight: 1.5,
                  ml: 4
                }}>
                  Schedule regular check-ins to monitor advancement toward goals.
                </p>
              </div>
              
              <div>
                <div sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2,
                  mb: 1
                }}>
                  <RiCheckLine sx={{ color: 'primary' }} />
                  <p sx={{
                    fontSize: '0.95rem',
                    fontWeight: 600,
                    color: 'text'
                  }}>
                    Celebrate Milestones
                  </p>
                </div>
                
                <p sx={{
                  fontSize: '0.9rem',
                  color: 'text',
                  opacity: 0.8,
                  lineHeight: 1.5,
                  ml: 4
                }}>
                  Acknowledge and celebrate progress to maintain motivation.
                </p>
              </div>
            </div>
            
            {/* Related Tools */}
            <div sx={{
              bg: 'backgroundMuted',
              p: 3,
              borderRadius: '8px',
              boxShadow: '0 2px 6px rgba(0,0,0,0.05)'
            }}>
              <h3 sx={{
                fontSize: '1.25rem',
                fontWeight: 600,
                color: 'primary',
                mb: 3
              }}>
                Related Tools
              </h3>
              
              <div sx={{
                mb: 3,
                pb: 3,
                borderBottom: '1px solid',
                borderColor: 'rgba(0,0,0,0.05)'
              }}>
                <Link to="/tools/performance-review-builder" sx={{
                  textDecoration: 'none'
                }}>
                  <h4 sx={{
                    fontSize: '1rem',
                    fontWeight: 600,
                    color: 'text',
                    mb: 1
                  }}>
                    Performance Review Builder
                  </h4>
                  
                  <p sx={{
                    fontSize: '0.9rem',
                    color: 'text',
                    opacity: 0.8,
                    mb: 2
                  }}>
                    Create comprehensive performance reviews that include goal progress.
                  </p>
                  
                  <div sx={{
                    display: 'flex',
                    alignItems: 'center',
                    color: 'primary',
                    fontWeight: 600,
                    fontSize: '0.9rem'
                  }}>
                    Try Tool <RiArrowRightSLine sx={{ ml: 1 }} />
                  </div>
                </Link>
              </div>
              
              <div>
                <Link to="/tools/development-plan-template" sx={{
                  textDecoration: 'none'
                }}>
                  <h4 sx={{
                    fontSize: '1rem',
                    fontWeight: 600,
                    color: 'text',
                    mb: 1
                  }}>
                    Development Plan Template
                  </h4>
                  
                  <p sx={{
                    fontSize: '0.9rem',
                    color: 'text',
                    opacity: 0.8,
                    mb: 2
                  }}>
                    Create a comprehensive plan for achieving your professional goals.
                  </p>
                  
                  <div sx={{
                    display: 'flex',
                    alignItems: 'center',
                    color: 'primary',
                    fontWeight: 600,
                    fontSize: '0.9rem'
                  }}>
                    Try Tool <RiArrowRightSLine sx={{ ml: 1 }} />
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      {/* FAQ Section */}
      <div sx={{
        bg: 'backgroundMuted',
        py: [4, 5]
      }}>
        <div sx={{
          maxWidth: '960px',
          mx: 'auto',
          px: 3
        }}>
          <h2 sx={{
            fontSize: '1.75rem',
            fontWeight: 700,
            textAlign: 'center',
            mb: 4,
            color: 'text'
          }}>
            Frequently Asked Questions About SMART Goals
          </h2>
          
          <div sx={{
            bg: 'white',
            borderRadius: '10px',
            p: 4,
            boxShadow: '0 2px 6px rgba(0,0,0,0.05)'
          }}>
            <div sx={{
              mb: 4,
              pb: 4,
              borderBottom: '1px solid',
              borderColor: 'rgba(0,0,0,0.06)'
            }}>
              <h3 sx={{
                fontSize: '1.25rem',
                fontWeight: 600,
                color: 'text',
                mb: 2,
                display: 'flex',
                alignItems: 'center',
                gap: 2
              }}>
                <RiQuestionLine sx={{ color: 'primary' }} />
                What exactly does SMART stand for in goal setting?
              </h3>
              
              <p sx={{
                fontSize: '1rem',
                color: 'text',
                lineHeight: 1.6,
                mb: 0
              }}>
                SMART is an acronym that stands for Specific, Measurable, Achievable, Relevant, and Time-bound. 
                Each element helps create well-structured goals: <strong>Specific</strong> means clearly defining what you want to accomplish; 
                <strong>Measurable</strong> involves including concrete criteria to track progress; <strong>Achievable</strong> ensures 
                the goal is realistic given available resources; <strong>Relevant</strong> means the goal aligns with broader objectives; 
                and <strong>Time-bound</strong> establishes a clear timeline with deadlines.
              </p>
            </div>
            
            <div sx={{
              mb: 4,
              pb: 4,
              borderBottom: '1px solid',
              borderColor: 'rgba(0,0,0,0.06)'
            }}>
              <h3 sx={{
                fontSize: '1.25rem',
                fontWeight: 600,
                color: 'text',
                mb: 2,
                display: 'flex',
                alignItems: 'center',
                gap: 2
              }}>
                <RiQuestionLine sx={{ color: 'primary' }} />
                How often should goals be set and reviewed?
              </h3>
              
              <p sx={{
                fontSize: '1rem',
                color: 'text',
                lineHeight: 1.6,
                mb: 0
              }}>
                The optimal frequency for setting goals depends on your context. In professional settings, many organizations 
                establish annual goals with quarterly reviews. For personal development, a combination of long-term goals (1-3 years) 
                and short-term goals (1-3 months) often works well. Regardless of timeframe, regular progress reviews are essential—weekly 
                or biweekly check-ins for short-term goals and monthly reviews for longer-term objectives help maintain momentum and allow 
                for timely adjustments.
              </p>
            </div>
            
            <div sx={{
              mb: 4,
              pb: 4,
              borderBottom: '1px solid',
              borderColor: 'rgba(0,0,0,0.06)'
            }}>
              <h3 sx={{
                fontSize: '1.25rem',
                fontWeight: 600,
                color: 'text',
                mb: 2,
                display: 'flex',
                alignItems: 'center',
                gap: 2
              }}>
                <RiQuestionLine sx={{ color: 'primary' }} />
                When is it appropriate to adjust or change goals?
              </h3>
              
              <p sx={{
                fontSize: '1rem',
                color: 'text',
                lineHeight: 1.6,
                mb: 0
              }}>
                Goals should be viewed as flexible frameworks rather than rigid requirements. It's appropriate to adjust goals when: 
                1) External circumstances change significantly (market shifts, resource availability); 
                2) You discover the goal is unrealistically ambitious or not challenging enough; 
                3) Organizational priorities shift; 
                4) New information becomes available that impacts feasibility; or 
                5) You've made substantial progress and need to extend the goal. The key is to document the reason for changes and ensure 
                the adjusted goal still maintains SMART criteria.
              </p>
            </div>
            
            <div sx={{
              mb: 4,
              pb: 4,
              borderBottom: '1px solid',
              borderColor: 'rgba(0,0,0,0.06)'
            }}>
              <h3 sx={{
                fontSize: '1.25rem',
                fontWeight: 600,
                color: 'text',
                mb: 2,
                display: 'flex',
                alignItems: 'center',
                gap: 2
              }}>
                <RiQuestionLine sx={{ color: 'primary' }} />
                What's the difference between goals and objectives?
              </h3>
              
              <p sx={{
                fontSize: '1rem',
                color: 'text',
                lineHeight: 1.6,
                mb: 0
              }}>
                Though often used interchangeably, goals and objectives have distinct meanings in professional development contexts. 
                A goal is typically a broader, higher-level outcome that you want to achieve, often with a longer timeframe. 
                Objectives are the specific, measurable actions or milestones that contribute to reaching the goal. Think of goals 
                as the destination and objectives as the roadmap. For example, a goal might be "Enhance team productivity," while 
                related objectives could include "Implement a new project management system by Q2" and "Reduce meeting time by 15% this quarter."
              </p>
            </div>
            
            <div sx={{
              mb: 4,
              pb: 4,
              borderBottom: '1px solid',
              borderColor: 'rgba(0,0,0,0.06)'
            }}>
              <h3 sx={{
                fontSize: '1.25rem',
                fontWeight: 600,
                color: 'text',
                mb: 2,
                display: 'flex',
                alignItems: 'center',
                gap: 2
              }}>
                <RiQuestionLine sx={{ color: 'primary' }} />
                How many goals should someone set at once?
              </h3>
              
              <p sx={{
                fontSize: '1rem',
                color: 'text',
                lineHeight: 1.6,
                mb: 0
              }}>
                Quality supersedes quantity when it comes to goal setting. Research suggests that focusing on 3-5 meaningful goals 
                at a time yields better results than pursuing numerous objectives simultaneously. This focused approach prevents 
                cognitive overload and resource dilution. For professional development, consider having 2-3 performance goals and 
                1-2 development goals in any given period. If you're in a leadership position, you might also have 1-2 team or 
                organizational goals. Remember that each goal may have multiple supporting objectives or action steps.
              </p>
            </div>
            
            <div>
              <h3 sx={{
                fontSize: '1.25rem',
                fontWeight: 600,
                color: 'text',
                mb: 2,
                display: 'flex',
                alignItems: 'center',
                gap: 2
              }}>
                <RiQuestionLine sx={{ color: 'primary' }} />
                How do you measure "soft" goals like improving communication?
              </h3>
              
              <p sx={{
                fontSize: '1rem',
                color: 'text',
                lineHeight: 1.6,
                mb: 0
              }}>
                "Soft" goals involving skills like communication, leadership, or collaboration can be measured using a combination of 
                quantitative and qualitative metrics. For communication improvement, consider tracking: 1) Feedback scores from peers 
                or managers through structured assessments; 2) Specific communication behaviors (e.g., "Provide project updates to 
                stakeholders weekly"); 3) Impact measurements (e.g., "Reduce clarification questions by 20%"); 4) Self-assessment 
                ratings on specific aspects of communication; or 5) Completion of relevant training or courses with pre/post-assessments. 
                The key is to transform abstract concepts into observable behaviors or outcomes.
              </p>
            </div>
          </div>
        </div>
      </div>
      
      {/* CTA Section */}
      <div sx={{
        bg: 'white',
        py: [4, 5],
        textAlign: 'center'
      }}>
        <div sx={{
          maxWidth: '800px',
          mx: 'auto',
          px: 3
        }}>
          <h2 sx={{
            fontSize: '1.75rem',
            fontWeight: 700,
            color: 'text',
            mb: 3
          }}>
            Ready to Set Meaningful Goals?
          </h2>
          
          <p sx={{
            fontSize: '1.1rem',
            color: 'text',
            lineHeight: 1.5,
            mb: 4
          }}>
            Download our templates and start creating SMART goals that drive success 
            and professional development.
          </p>
          
          <div sx={{
            display: 'flex',
            gap: 3,
            justifyContent: 'center',
            flexWrap: 'wrap'
          }}>
            <Link to="/contact" sx={{
              display: 'inline-flex',
              alignItems: 'center',
              gap: 2,
              bg: 'primary',
              color: 'white',
              py: 2,
              px: 3,
              borderRadius: '6px',
              fontSize: '1rem',
              fontWeight: 600,
              textDecoration: 'none',
              transition: 'transform 0.2s ease, box-shadow 0.2s ease',
              '&:hover': {
                transform: 'translateY(-2px)',
                boxShadow: '0 4px 8px rgba(0,0,0,0.1)'
              }
            }}>
              Get Free Templates <RiDownloadLine />
            </Link>
            
            <Link to="/guides/goal-setting" sx={{
              display: 'inline-flex',
              alignItems: 'center',
              gap: 2,
              bg: 'white',
              color: 'text',
              py: 2,
              px: 3,
              borderRadius: '6px',
              fontSize: '1rem',
              fontWeight: 600,
              textDecoration: 'none',
              transition: 'transform 0.2s ease',
              '&:hover': {
                transform: 'translateY(-2px)'
              }
            }}>
              Read Goal Setting Guide <RiArrowRightSLine />
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default GoalSettingTemplatePage 